import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import ProductModel from '../model/ProductModel';
import RetailerModel from '../model/ClientModel';
import DisplayUtil from './DisplayUtil';

class OrderPlaceView extends React.Component<CommonProps, CommonState> {

    retailerModel: RetailerModel;
    productModel: ProductModel;
    msisdn: string = "";

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.retailerModel = new RetailerModel();
        this.productModel = new ProductModel();
        this.retailerModel.channelId = DataStore.getSelectedRetailerId();
        this.productModel.productId = DataStore.getSelectedProductId();
        this.loadData();
    }

    async loadData() {

        {
            let sa = new ServerAdaptor();
            let cr = await sa.get_client(this.retailerModel.channelId);
            this.retailerModel = cr.data as RetailerModel;
        }

        {
            let model = { "channelId": this.retailerModel.channelId, "productId": this.productModel.productId };
            let sa = new ServerAdaptor();
            let cr = await sa.get_product(model);
            this.productModel = cr.data as ProductModel;
        }

        this.setState({
            time: new Date()
        });
    }

    updateInput(e: React.ChangeEvent<HTMLInputElement>, typeHint: string) {
        if (typeHint === "msisdn") {
            this.msisdn = e.target.value;
        }
        this.setState({
            time: new Date(),
        });
    }

    async placeOrderAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
    
        let model = { "channelId": this.retailerModel.channelId, "productCode": this.productModel.productCode, "msisdn": this.msisdn };
        let sa = new ServerAdaptor();
        let cr = await sa.place_order(model);
            if(cr.status === 200) {
                console.log("response : "+cr.data);
                DisplayUtil.showInfoModel("Info", "Success", "", "/retailer-products");
            }else {
                if(cr.status === 501) {
                    DisplayUtil.showInfoModel("Information", "Error", JSON.stringify(cr.error), "");
                }else if(cr.status === 502) {
                    DisplayUtil.showInfoModel("Information", "Error", cr.error, "");
                }else {
                    console.log("response : FAILED");
                }
            }
    }

    render() {

        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Order Placing</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-8">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-6">
                                    <h4 className="mb-1">Order Details</h4>
                                </div>
                                <div className="mb-6">
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Retailer Msisdn </label>
                                        <label data-for="phone" className="col-sm-8 col-form-label form-label">{this.retailerModel.mobile}</label>
                                    </div>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Product </label>
                                        <div data-for="phone" className="col-sm-8 col-form-label form-label">
                                            <h5 className=" mb-1">{this.productModel.productName}</h5>
                                            <p className="mb-0 mt-3">{this.productModel.description}</p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div data-for="phone" className="col-sm-4 col-form-label form-label">Price </div>
                                        <div data-for="phone" className="col-sm-8 col-form-label form-label">
                                            <div className="d-flex align-items-center">
                                                <div style={{width:'30px'}}>
                                                    {this.productModel.currencyName}
                                                </div>
                                                <div className="ms-3 h5 mb-0">
                                                    {this.productModel.price}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div data-for="phone" className="col-sm-4 col-form-label form-label">Send Price </div>
                                        <div data-for="phone" className="col-sm-8 col-form-label form-label">
                                        <div className="d-flex align-items-center">
                                                <div style={{width:'30px'}}>
                                                    {this.productModel.sendCurrencyName}
                                                </div>
                                                <div className="ms-3 h5 mb-0">
                                                    {this.productModel.sendPrice}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form>
                                    <div className="mb-3 row">
                                        <label data-for="phone" className="col-sm-4 col-form-label form-label">Msisdn </label>
                                        <div className="col-md-8 col-12">
                                            <input type="text" className="form-control" placeholder="Msisdn" onChange={(e) => this.updateInput(e, "msisdn")}/>
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="offset-md-4 col-md-8 mt-4">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => this.placeOrderAction(e)}>Place Order</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default OrderPlaceView;